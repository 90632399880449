import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import { assign } from 'lodash';

interface IGallery {
    width?: number;
    containerClass: string;
    slidesPerView?: number;
    centeredSlides?: boolean;
    breakpoints?: any;
    spaceBetween?: number;
    navigation?: any;
    pagination?: any;
    autoHeight?: boolean;
    autoplay?: any;
    height?: number;
    loop?: boolean;
    autoplayVideo?: boolean;
    pageNumberPreposition?: string;
}


class Gallery {
    //these params are exposed to the file you intialize your gallery in, so you can initialize multiple
    //gallery versions across your project or just be able to configure without diving into this file
    params: IGallery = {
        containerClass: 'adage-gallery-js',
        centeredSlides: true,
        slidesPerView: 1,
        breakpoints: {
            640: {
                spaceBetween: 0
            }
        },
        spaceBetween: 0,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
        autoplay: {
            delay: 4000,
        },
        loop: true,
        pageNumberPreposition: ' of ',
        autoHeight: true
    };
    gallery: any;
    activeSlide: any;
    previousSlide: any;
    activeIndex: any;
    previousIndex: number; // swiper's previous index implementation doesn't work with loop: true so we need to do it manually

    galleryId: string;

    constructor(params?: IGallery) {

        assign(this.params, params);
        Swiper.use([Navigation, Pagination, Autoplay]);
        this.gallery = new Swiper(`.${this.params.containerClass}`, {
            // feel free to add any other Swiper settings if needed
            slidesPerView: this.params.slidesPerView,
            centeredSlides: this.params.centeredSlides,
            spaceBetween: this.params.spaceBetween,
            breakpoints: this.params.breakpoints,
            navigation: this.params.navigation,
            pagination: this.params.pagination,
            autoplay: this.params.autoplay,
            loop: this.params.loop,
            on: {
                slideChangeTransitionStart: this.handleSlide.bind(this)
            },
            enabled: true,
            autoHeight: this.params.autoHeight
        });
        this.galleryId = this.gallery.el.getAttribute('data-unique-id');
        this.initCallback();
        setTimeout(() => this.gallery.resize.resizeHandler(), 2000); // SUPER hack... but it works
    }

    initCallback() {
        this.activeSlide = this.gallery.slides[this.gallery.activeIndex];
        this.activeIndex = this.gallery.activeIndex;
        this.setPageNumbers();
      
    }

    setPageNumbers() {
        if (this.gallery) {
            let totalSlides = this.gallery.el.getAttribute('data-total-slides');
            for (let i = 0; i < this.gallery.slides.length; i++) {
                let slide = this.gallery.slides[i];
                this.reconcileDupeId(slide);

                let slidePageContainer = slide.querySelector('[data-gallery-page]');
                if (slidePageContainer) {
                    let pageString = `${parseInt(slide.getAttribute('data-slide-index')) + 1}${this.params.pageNumberPreposition}${totalSlides}`;
                    slidePageContainer.innerHTML = pageString;
                }
            }

        }
    }

    reconcileDupeId(slide) {
        // check to see if there's 2 slides with same unique ID (when loop is true, slides get duplicated)
        if (slide.querySelector('[data-unique-id]')) {
            let thisId = slide.querySelector('[data-unique-id]').getAttribute('data-unique-id');
            if (this.gallery.el.querySelectorAll(`[data-unique-id="${thisId}"]`).length > 1) {
                let dupes = Array.prototype.slice.call(this.gallery.el.querySelectorAll(`[data-unique-id="${thisId}"]`));
                dupes.forEach((dupe, index) => {
                    dupe.setAttribute('data-unique-id', `${thisId}-${index}`);
                })
            }
        }
    }

    handleSlide() {
        if (this.gallery) {
            this.previousSlide = this.activeSlide;
            this.previousIndex = this.activeIndex;
            this.activeSlide = this.gallery.slides[this.gallery.activeIndex];
            this.activeIndex = this.gallery.activeIndex;
            let slideAutplay = Array.prototype.slice.call(this.gallery.el.querySelectorAll(`.swiper-slide`))
            slideAutplay.forEach((slide) => {
                slide.addEventListener('mouseenter', e => { this.gallery.autoplay.stop(); });
                slide.addEventListener('mouseleave', e => { this.gallery.autoplay.start(); });
            })
        }
    }
}

(function () {

function nodeListToArray(nodeList: NodeList): any[] {
    return Array.prototype.slice.call(nodeList);
    }

const galleryClass = 'single-item-gallery';
const galleries = nodeListToArray(document.querySelectorAll('[data-gallery]'));

galleries.forEach((gallery, index) => {
    gallery.classList.add(`${galleryClass}-${index}`);

    new Gallery({
        containerClass: `${galleryClass}-${index}`,
        slidesPerView: 1,
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
        autoplay: {
            delay: 4000,
        }
    })
});

})();
